@import "components/scene/colors";


.App {
    width: 100vw;
    height: 100vh;
}


.alphaGrid {
    background-image: url("./assets/img/alpha.jpg");
    background-repeat: repeat;
    background-size: 300px;
}


.pointing {
    cursor: pointer;
}


.rangeBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;
    border-radius: 20px;
    @include uiColor();
}

.rangeTrack {
    width: 100%;
    height: 20px;
    border-radius: 50px;
    @include uiColor();
}

.rangeThumb {
    @include uiColorInv();
    width: 14px;
    height: 14px;
    border-radius: 20px;
}