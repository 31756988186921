@import "../scene/colors";


.materialPopup {
    @include uiColor();
    width: 150px;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    z-index: 2;
}


.materialPopup p {
    font-size: 12px;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.materialPopup p:first-of-type {
    margin-top: 0px;
}


.materialPopup input[type=color] {
    width: 100px;
    height: 20px;
    margin: 0px;
}
::-webkit-color-swatch {
    border-color: transparent;
}
::-moz-color-swatch {
    border-color: transparent;
}


.materialPopup input[type=checkbox] {
    width: 20px;
    height: 20px;
    margin: 0px;
}


.materialPopup {
    .rangeBackground {
        @include uiColorInv();
    }
    
    .rangeTrack {
        @include uiColorInv();
    }
    
    .rangeThumb {
        @include uiColor();
    }

    input[type=color] {
        border-radius: 20px;
        width: 100px;
        height: 25px;
        border: none;
        outline: none;
        border: 2px solid white;
        
        body.dark & {
            border-color: black;
        }
    }


    input[type=checkbox]{
        height: 0;
        width: 0;
        visibility: hidden;
    }
    
    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 40px;
        height: 20px;
        background: grey;
        display: block;
        border-radius: 100px;
        position: relative;
    }
    
    label:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }
    
    input:checked + label {
        background: #55da81;
    }
    
    input:checked + label:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
    }
    
    label:active:after {
        width: 2px;
    }
}